<template>
  <div class="intro-page">
    <div class="intro-container">
      <h1 class="intro-title">民生银行北京分行心理健康素养测评</h1>
      <!-- <h1 class="intro-title">调查问卷</h1> -->
      <div class="content1">
        亲爱的同事：<br />
        您好！
      </div>

      <div class="content">
        心理健康是我们幸福生活和快乐工作的基石。心理健康素养，是指综合运用心理健康知识、技能和态度，保持和促进心理健康的能力，心理健康素养的提升能有效促进个体的心理健康水平。据测算，我国当前居民心理健康素养水平的基线值为12%，预期到2022年和2030年，居民心理健康素养水平提升到20%和30%。<br /><br />
      </div>
      <div class="content">
        本次测评可以帮助员工评估自身心理健康素养和当前心理健康水平。测评提交后会即时产出个人报告，能够科普心理健康方面的知识，提出针对性的、在日常工作和生活中切实可行的建议，有利于提升员工自身心理健康水平。<br /><br />
      </div>
      <div class="content">
        本次测评采用匿名形式进行，由第三方专业机构进行信息收集及分析，个人报告结果只能本人查看，团体报告只呈现整体的趋势性数据。测评过程会严格保护您的个人隐私，请放心作答。
      </div>

      <button class="start-button" @click="startAssessment">开始测评</button>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";

export default {
  methods: {
    startAssessment() {
      const userId = localStorage.getItem("userId");
      if (!userId || userId == "undefined") {
        Toast("请先登录");
        setTimeout(() => {
          this.$router.push("/cp/minsheng/login");
        }, 1000);
        return;
      }
      // 跳转到测评页面
      this.$router.push("/CP/minsheng/answer");
    },
  },
};
</script>

<style scoped>
/* 引导页整体布局 */
.intro-page {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
  min-height: 100vh;
  /* background-color: #f9f9f9; */
  padding: 20px;
  box-sizing: border-box;
  background-image: url("https://lesson.iapeap.com/images/cp/wuzi.png");
  background-size: 100% 100%;
  /* height: 100vh; */
}

.intro-container {
  max-width: 720px;
  background: rgb(255, 255, 255, 0.8);
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 30px 20px;
  box-sizing: border-box;
  text-align: center;
}

/* 标题样式 */
.intro-title {
  font-size: 1.8em;
  color: #333;
  margin-bottom: 10px;
  font-weight: bold;
}

/* 正文内容样式 */
.intro-text {
  line-height: 1.8;
  color: #333;
  text-align: left;
  margin-bottom: 10px;
}

/* 按钮样式 */
.start-button {
  background-color: #76c7c0;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 20px;
}

.start-button:hover {
  background-color: #5ba9a1;
}

/* 响应式处理 */
@media (max-width: 768px) {
  .intro-container {
    padding: 20px;
  }
  .intro-title {
    font-size: 1em;
  }
  .start-button {
    padding: 8px 16px;
  }
}
.content {
  text-align: left;
  text-indent: 20px;
}
.content1 {
  text-align: left;
  margin-bottom: 20px;
}
</style>
